export type TemplateBaseComponentSubset = ComponentSubset
export interface TemplateBaseComponentGroupSubset extends ComponentGroupSubset {
  components: BaseComponentGroup["components"]
}

export interface TemplateComponent {
  _id: string
  baseComponent: TemplateBaseComponentSubset
  description?: string
  sortOrder: number
  optionalProperties?: Array<string>
}

export interface TemplateComponentGroup {
  _id: string
  baseComponentGroup: TemplateBaseComponentGroupSubset
  description?: string
  components: Array<TemplateComponent>
  sortOrder: number
  tag?: string
}

export interface Template {
  _id: string
  organization?: string
  description: string
  componentGroups: Array<TemplateComponentGroup>
  domain: DomainType
}

export interface TemplateUsedByResponseRecord {
  /** Id of the organization */
  organizationId: string
  /** Description of the organization */
  description: string
  /** Ids of the assets */
  assets: Array<string>
}

export interface TemplateUsedByResponse {
  templateId: string
  organizations: Array<TemplateUsedByResponseRecord>
  totalAssets: number
}

export interface SmallPopulatedTemplate {
  _id: string
  description: string
  organization?: null | Pick<Organization, "_id" | "description">
}

export const useTemplateStore = defineStore("template", () => {
  const getTemplates = async () =>
    useCrudMethods<SmallPopulatedTemplate>("/templates").readItems({
      pagination: false,
      select: JSON.stringify({
        description: 1,
        organization: 1,
      }),
      populate: JSON.stringify([
        {
          path: "organization",
          model: "Organization",
          select: "description",
        },
      ]),
      sort: JSON.stringify({
        description: 1,
      }),
    })

  const getAllTemplates = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<Template>> => {
    const query = getUrlSearchParams(queryParameters)
    const { data } = await useSamApi<PaginateResult<Template>>(`/templates?${query}`)
    return data
  }

  const getOneById = async (id: string): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${id}`)
    return data
  }

  const addTemplate = async (
    description: string,
    domain: DomainType,
    organization?: string,
    copyFromTemplate?: string,
  ): Promise<Template> => {
    const { data } = await useSamApi<Template>("/templates", {
      method: "POST",
      body: JSON.stringify({
        description,
        domain,
        organization,
        copyFromTemplate,
      }),
    })
    return data
  }

  const updateTemplate = async (id: string, domain: DomainType, description?: string, organization?: string): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${id}`, {
      method: "PUT",
      body: JSON.stringify({ domain: domain, description: description, organization: organization ?? "" }),
    })
    return data
  }

  const deleteTemplate = async (id: string): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${id}`, {
      method: "DELETE",
    })
    return data
  }

  const addComponentGroup = async (templateId: string, payload: AddAssetComponentGroupPayload): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${templateId}/component-group`, {
      method: "POST",
      body: JSON.stringify({ componentGroupId: payload.baseComponentGroup, description: payload.description, tag: payload.tag }),
    })
    return data
  }

  const updateComponentGroup = async (
    templateId: string,
    templateComponentGroupId: string,
    payload: UpdateAssetComponentGroupPayload,
  ): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${templateId}/component-group/${templateComponentGroupId}`, {
      method: "PUT",
      body: JSON.stringify(payload),
    })
    return data
  }

  const removeComponentGroup = async (templateId: string, templateComponentGroupId: string): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${templateId}/component-group/${templateComponentGroupId}`, {
      method: "DELETE",
    })
    return data
  }

  const updateComponentGroupSort = async (
    templateId: string,
    templateComponentGroupId: string,
    movementType: MovementType,
  ): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${templateId}/component-group/${templateComponentGroupId}/sort`, {
      method: "PUT",
      body: JSON.stringify({ movement: movementType }),
    })
    return data
  }

  const updateComponentSort = async (
    templateId: string,
    templateComponentGroupId: string,
    templateComponentId: string,
    movementType: MovementType,
  ): Promise<Template> => {
    const { data } = await useSamApi<Template>(
      `/templates/${templateId}/component-group/${templateComponentGroupId}/component/${templateComponentId}/sort`,
      {
        method: "PUT",
        body: JSON.stringify({ movement: movementType }),
      },
    )
    return data
  }

  const addComponent = async (
    templateId: string,
    templateComponentGroupId: string,
    baseComponentId: string,
    description?: string,
  ): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${templateId}/component-group/${templateComponentGroupId}/component`, {
      method: "POST",
      body: JSON.stringify({ componentId: baseComponentId, description: description }),
    })
    return data
  }

  const updateComponent = async (
    templateId: string,
    templateComponentGroupId: string,
    templateComponentId: string,
    description?: string,
    optionalProperties?: Array<string>,
  ): Promise<Template> => {
    const { data } = await useSamApi<Template>(
      `/templates/${templateId}/component-group/${templateComponentGroupId}/component/${templateComponentId}`,
      {
        method: "PUT",
        body: JSON.stringify({ componentId: templateComponentId, description: description, optionalProperties: optionalProperties }),
      },
    )
    return data
  }

  const removeComponent = async (templateId: string, templateComponentGroupId: string, templateComponentId: string): Promise<Template> => {
    const { data } = await useSamApi<Template>(
      `/templates/${templateId}/component-group/${templateComponentGroupId}/component/${templateComponentId}`,
      {
        method: "DELETE",
      },
    )
    return data
  }

  const getTemplateUsedBy = async (templateId: string): Promise<TemplateUsedByResponse> => {
    const { data } = await useSamApi<TemplateUsedByResponse>(`/templates/${templateId}/used-by`)
    return data
  }

  return {
    getTemplates,
    getAllTemplates,
    getOneById,
    addTemplate,
    updateTemplate,
    deleteTemplate,

    addComponentGroup,
    updateComponentGroup,
    removeComponentGroup,

    updateComponentGroupSort,
    updateComponentSort,

    addComponent,
    updateComponent,
    removeComponent,

    getTemplateUsedBy,
  }
})
